import { Link, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import Highlighter from "react-highlight-words";

import { alphaNumeric } from "../../ui/admin/Helper";
import { findChunks, getDescription, stripHtml } from "./Helper";

export default function SearchList({ arr, handleClose, searchText, searchWords, callback }) {
  return (
    <List>
      {arr.map((item, idx) => (
        <ListItemButton
          key={idx}
          sx={{
            borderRadius: "16px"
          }}
          component={Link}
          href={`/${item.hub_id}/${alphaNumeric(item.room_name)}/${item.collection_id}`}
          onClick={() => {
            localStorage.setItem("searchText", searchText);
            handleClose();
            if (typeof callback == "function") {
              callback();
            }
          }}
        >
          <ListItemText
            primary={
              <Typography
                variant="body1"
                style={{
                  fontWeight: "bold",
                  textTransform: "capitalize"
                }}
                gutterBottom
              >
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={searchWords}
                  autoEscape={true}
                  findChunks={findChunks}
                  textToHighlight={item.name}
                />
              </Typography>
            }
            secondary={
              <Highlighter
                highlightClassName="highlight"
                searchWords={searchWords}
                autoEscape={true}
                findChunks={findChunks}
                textToHighlight={`${item.description ? getDescription(stripHtml(item.description), searchText) : ""}${
                  item.creator ? " - " + item.creator : ""
                }${item.room_name ? " | " + item.room_name : ""}`}
              />
            }
          />
        </ListItemButton>
      ))}
    </List>
  );
}
