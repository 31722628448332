import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  SvgIcon,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import InputText from "../../../../ui/admin/Forms/InputText";
import DialogSwitch from "../../../../ui/user/DialogSwitch";
import { createAndRedirectToNewHub, useAuth } from "../../../helper";
import SignInJWT from "../../../SignInJWT";
import SelectScene from "./SelectScene";

const CreateRoomMessage = () => (
  <FormattedMessage id="link-page.create-room-button" defaultMessage="Create Meeting Room / Virtual Exhibition" />
);

export function CreateRoomButton(props) {
  const auth = useAuth();
  const [mode, setMode] = useState();
  const [open, setOpen] = useState(false);

  const [roomName, setRoomName] = useState();
  const [sceneId, setSceneId] = useState();

  const canCreate = auth.isSignedIn ? auth.email.includes("dinus.ac.id") || auth.isAdmin : false;

  return (
    <>
      {auth.isSignedIn ? (
        <Button
          size="large"
          variant="contained"
          color="primary"
          startIcon={
            <SvgIcon>
              <AddCircleOutlineIcon />
            </SvgIcon>
          }
          onClick={() => {
            setOpen(true);
          }}
          {...props}
        >
          <CreateRoomMessage />
        </Button>
      ) : (
        <SignInJWT
          callback={() => {
            setOpen(true);
          }}
          custom_button={handleOpen => (
            <Button
              size="large"
              variant="contained"
              color="primary"
              startIcon={
                <SvgIcon>
                  <AddCircleOutlineIcon />
                </SvgIcon>
              }
              onClick={handleOpen}
              {...props}
            >
              <CreateRoomMessage />
            </Button>
          )}
        />
      )}

      <DialogSwitch
        forceFullscreen={false}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth={auth.isSignedIn ? "md" : "xs"}
        opacity={0.8}
        title="Create room"
      >
        <Box p={2} pb={4}>
          {auth.isSignedIn && (
            <>
              {mode == "common" ? (
                <>
                  <Button
                    onClick={() => {
                      setMode(null);
                    }}
                    startIcon={<ChevronLeftIcon />}
                    sx={{ mb: 2 }}
                  >
                    Back
                  </Button>
                  <InputText
                    name="name"
                    label={
                      <>
                        <FormattedMessage id="room-settings-sidebar.name-placeholder" defaultMessage="Room name" />
                        {" (optional)"}
                      </>
                    }
                    value={roomName}
                    setValue={setRoomName}
                  />

                  <Typography sx={{ mt: 2 }} variant="body1" gutterBottom>
                    <FormattedMessage id="choose-scene" defaultMessage="Choose scene" />
                  </Typography>

                  <Button
                    startIcon={<AddIcon fontSize="small" />}
                    component="a"
                    target="_blank"
                    href="/spoke"
                    variant="contained"
                    size="small"
                    disabled={!canCreate}
                  >
                    <FormattedMessage id="create-new-scene" defaultMessage="Create new building" />
                  </Button>

                  {!canCreate && (
                    <Alert sx={{ mt: 1 }} severity="warning">
                      <FormattedMessage
                        id="must-login-to-create-scene"
                        defaultMessage="Please login with udinus email (mhs.dinus.ac.id / dsn.dinus.ac.id) to be able to create a custom building"
                      />
                    </Alert>
                  )}

                  <SelectScene sx={{ mt: 2, mb: 2 }} value={sceneId} setValue={setSceneId} />

                  <Grid container justifyContent="center">
                    <Button
                      disabled={!sceneId}
                      variant="contained"
                      size="large"
                      onClick={() => {
                        createAndRedirectToNewHub(roomName, sceneId, false);
                      }}
                    >
                      Create Room
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid container spacing={4}>
                  <Grid item md={6} sm={6} xs={12}>
                    <Card elevation={0} className="bg-gradient-to-r from-pink-100 via-purple-100 to-indigo-100">
                      <CardActionArea
                        onClick={() => {
                          setMode("common");
                        }}
                      >
                        <CardMedia sx={{ height: "200px" }} image={"/user/dinus/vr-chat.svg"} />
                        <CardContent>
                          <Typography variant="h5" gutterBottom>
                            Meeting Room
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <Card elevation={0} className="bg-gradient-to-r from-yellow-100 via-pink-100 to-pink-100">
                      <CardActionArea component="a" href="/user/quick-create">
                        <CardMedia sx={{ height: "200px" }} image={"/user/dinus/exhibition.svg"} />
                        <CardContent>
                          <Typography variant="h5" gutterBottom>
                            Virtual Exhibition
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Box>
      </DialogSwitch>
    </>
  );
}
