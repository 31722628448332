import { Button, Container, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { alphaNumeric, formSubmit, getLaravelURL } from "../ui/admin/Helper";
import { Box, Checkbox, FormHelperText, Link } from "@mui/material";
import ButtonHelp from "../ui/user/Button/ButtonHelp";
import { useState } from "react";
import { useSnackbar } from "notistack";

const ExhibitorRegister = ({ continueSignIn, setMode }) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      enableReinitialize
      initialValues={{
        cover: null,
        avatar: null,
        email: "",
        name: "",
        username: "",
        password: "",
        password_confirmation: "",
        policy: false,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(255)
          .required("Name is required"),
        username: Yup.string()
          .max(30)
          .matches(/^.[a-z0-9]+$/, {
            message: "Hanya boleh huruf kecil dan angka, tidak boleh ada spasi",
            excludeEmptyString: true
          })
          .required("Organization name is required"),

        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string()
          .min(7)
          .max(255)
          .required("Password is required"),
        password_confirmation: Yup.string().test("passwords-match", "Passwords must match", function(value) {
          return this.parent.password === value;
        }),
        policy: Yup.boolean().oneOf([true], "This field must be checked")
      })}
      onSubmit={(values, controls) => {
        formSubmit(
          values,
          controls,
          "post",
          `${getLaravelURL()}/api/account/register`,
          enqueueSnackbar,
          data => {
            // console.log("Success Register");
            // console.log(data);
            continueSignIn(data);
          },
          {
            isSuccessMessage: false,
            isSendFile: true
            // successMessage: "Success Register"
          }
        );
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Container disableGutters>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              Organization Information
            </Typography>

            <TextField
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label="Name"
              margin="normal"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.username && errors.username)}
              fullWidth
              helperText={touched.username && errors.username}
              label="Organization name"
              margin="normal"
              name="username"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username}
              variant="outlined"
            />

            {values.username != "" && (
              <Typography variant="body2">
                Custom URL : {`${location.origin}/user/${alphaNumeric(values.username)}`}
              </Typography>
            )}

            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.password_confirmation && errors.password_confirmation)}
              fullWidth
              helperText={touched.password_confirmation && errors.password_confirmation}
              label="Password confirmation"
              margin="normal"
              name="password_confirmation"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password_confirmation}
              variant="outlined"
            />

            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                ml: -1,
                mt: 2
              }}
            >
              <Checkbox checked={values.policy} color="primary" name="policy" onChange={handleChange} />
              <Typography color="textSecondary" variant="body2">
                I have read the{" "}
                <Link color="primary" component="a" target="_blank" href="/docs/pages/term-of-service">
                  Terms and Conditions
                </Link>
              </Typography>
            </Box>
            {Boolean(touched.policy && errors.policy) && <FormHelperText error>{errors.policy}</FormHelperText>}

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Container>

          <Container sx={{ mt: 3 }} maxWidth="xs">
            <Button sx={{ mb: 2 }} color="primary" fullWidth type="submit" variant="contained">
              Register
            </Button>

            <Button
              fullWidth
              onClick={() => {
                setMode("signin");
              }}
              component="div"
              color="inherit"
              variant="contained"
            >
              Sign in
            </Button>
          </Container>
        </form>
      )}
    </Formik>
  );
};

const VisitorRegister = ({ continueSignIn, setMode }) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      enableReinitialize
      initialValues={{
        cover: null,
        avatar: null,
        email: "",
        name: "",
        username: "",
        password: "",
        password_confirmation: "",
        policy: false,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(255)
          .required("Name is required"),

        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string()
          .min(7)
          .max(255)
          .required("Password is required"),
        password_confirmation: Yup.string().test("passwords-match", "Passwords must match", function(value) {
          return this.parent.password === value;
        }),
        policy: Yup.boolean().oneOf([true], "This field must be checked")
      })}
      onSubmit={(values, controls) => {
        formSubmit(
          values,
          controls,
          "post",
          `${getLaravelURL()}/api/account/register`,
          enqueueSnackbar,
          data => {
            // console.log("Success Register");
            // console.log(data);
            continueSignIn(data);
          },
          {
            isSuccessMessage: false,
            isSendFile: true,
            successMessage: "Success Register"
          }
        );
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Container disableGutters>
            <TextField
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label="Name"
              margin="normal"
              name="name"
              onBlur={handleBlur}
              onChange={props => {
                setFieldValue("username", alphaNumeric(props.target.value));
                handleChange(props);
              }}
              value={values.name}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.password_confirmation && errors.password_confirmation)}
              fullWidth
              helperText={touched.password_confirmation && errors.password_confirmation}
              label="Password confirmation"
              margin="normal"
              name="password_confirmation"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password_confirmation}
              variant="outlined"
            />

            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                ml: -1,
                mt: 2
              }}
            >
              <Checkbox checked={values.policy} color="primary" name="policy" onChange={handleChange} />
              <Typography color="textSecondary" variant="body2">
                I have read the{" "}
                <Link color="primary" component="a" target="_blank" href="/docs/pages/term-of-service">
                  Terms and Conditions
                </Link>
              </Typography>
            </Box>
            {Boolean(touched.policy && errors.policy) && <FormHelperText error>{errors.policy}</FormHelperText>}

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Container>

          <Container sx={{ mt: 3 }} maxWidth="xs">
            <Button sx={{ mb: 2 }} color="primary" fullWidth type="submit" variant="contained">
              Register
            </Button>

            <Button
              fullWidth
              onClick={() => {
                setMode("signin");
              }}
              component="div"
              color="inherit"
              variant="contained"
            >
              Sign in
            </Button>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default function Register({ continueSignIn, setMode }) {
  const [role, setRole] = useState("visitor");

  return (
    <>
      <Typography align="center" variant="h3" gutterBottom>
        Register
      </Typography>

      <Box mb={3}>
        <Typography variant="body1" gutterBottom>
          Ada kesulitan dalam mendaftar atau ada pertanyaan?
        </Typography>

        <ButtonHelp />
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={role}
          onChange={(event, newValue) => {
            setRole(newValue);
          }}
          aria-label="Role"
        >
          <Tab label="Visitor" value="visitor" />
          <Tab label="Exhibitor" value="exhibitor" />
        </Tabs>
      </Box>

      {role == "visitor" && <VisitorRegister setMode={setMode} continueSignIn={continueSignIn} />}
      {role == "exhibitor" && <ExhibitorRegister setMode={setMode} continueSignIn={continueSignIn} />}
    </>
  );
}
