import { FormHelperText, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

var timeout = null;

export default function Search({ setSearch, label = "Search room", placeholder = "", size = "medium", ...rest }) {
  const [text, setText] = useState("");

  const doSearch = e => {
    let str = e.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      let text = str
        .replace(/[,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        .toLowerCase()
        .replace(/\s+/g, " ")
        .trim();

      setSearch(text);
    }, 1000);

    setText(str);
  };

  return (
    <>
      <TextField
        label={label}
        fullWidth={true}
        type="search"
        value={text}
        onChange={doSearch}
        variant="outlined"
        size={size}
        {...rest}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      {text.length > 0 && text.length < 3 && <FormHelperText>At least 3 character to search !</FormHelperText>}
    </>
  );
}
