import { experimentalStyled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";

// import { Outlet } from "react-router-dom";
import { SocialBar } from "./SocialBar";
import MainNavbar from "./MainNavbar";
import MainSidebar from "./MainSidebar";

const MainLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: "100%"
}));

const MainLayout = ({ children }) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);

  return (
    <MainLayoutRoot>
      <MainNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />
      <MainSidebar onMobileClose={() => setIsSidebarMobileOpen(false)} openMobile={isSidebarMobileOpen} />
      {children}
      <SocialBar />
    </MainLayoutRoot>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
