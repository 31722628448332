import ParkOutlinedIcon from "@mui/icons-material/ParkOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Toolbar,
  Typography
} from "@mui/material";
import React, { useRef, useState } from "react";
import SignInJWT from "./SignInJWT";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useAuth } from "./helper";

const AccountPopover = ({ variant = "popover", size = 32, custom_avatar, callbackLogout = null, ...rest }) => {
  const anchorRef = useRef();
  const auth = useAuth();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {variant == "popover" ? (
        <>
          {!auth.isSignedIn ? (
            <SignInJWT {...rest} />
          ) : (
            <>
              {custom_avatar ? (
                custom_avatar(auth.avatar ? "/user/storage/avatar/" + auth.avatar : null, handleOpen, anchorRef)
              ) : (
                <Box
                  component={ButtonBase}
                  onClick={handleOpen}
                  ref={anchorRef}
                  sx={{
                    alignItems: "center",
                    display: "flex"
                  }}
                >
                  <Avatar
                    src={auth.avatar ? "/user/storage/avatar/" + auth.avatar : null}
                    sx={{
                      height: size,
                      width: size
                    }}
                  />
                </Box>
              )}

              <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                  horizontal: "center",
                  vertical: "bottom"
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                  sx: { width: 240 }
                }}
              >
                {auth.isSignedIn && (
                  <>
                    <Box sx={{ p: 2 }}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {auth.name}
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle2">
                        {auth.email}
                      </Typography>
                    </Box>
                    <Divider sx={{ width: "100%" }} />
                    <Box sx={{ mt: 2 }}>
                      {auth.isAdmin && (
                        <>
                          <MenuItem component="a" href="/admin">
                            <ListItemIcon>
                              <SettingsOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography color="textPrimary" variant="subtitle2">
                                  Dashboard Admin
                                </Typography>
                              }
                            />
                          </MenuItem>
                        </>
                      )}

                      <MenuItem component="a" href="/user/dashboard">
                        <ListItemIcon>
                          <DashboardIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography color="textPrimary" variant="subtitle2">
                              Dashboard Pameran
                            </Typography>
                          }
                        />
                      </MenuItem>
                      <MenuItem color="inherit" component="a" href="/spoke">
                        <ListItemIcon>
                          <ParkOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography color="textPrimary" variant="subtitle2">
                              Scene Editor
                            </Typography>
                          }
                        />
                      </MenuItem>
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <Button
                        color="inherit"
                        size="small"
                        fullWidth
                        onClick={() => {
                          handleClose();
                          auth.signOut().then(() => {
                            if (typeof callbackLogout == "function") {
                              callbackLogout();
                            }
                          });
                        }}
                        variant="outlined"
                      >
                        Logout
                      </Button>
                    </Box>
                  </>
                )}
              </Popover>
            </>
          )}
        </>
      ) : (
        <>
          {!auth.isSignedIn ? (
            <SignInJWT {...rest} />
          ) : (
            <>
              <Toolbar sx={{ p: 1 }}>
                <Avatar
                  src={auth.avatar ? "/user/storage/avatar/" + auth.avatar : null}
                  alt={auth.name}
                  title="Your avatar"
                  sx={{
                    height: size,
                    width: size,
                    m: 1
                  }}
                />
                <Box>
                  <Typography variant="subtitle2">{auth.name}</Typography>
                  <Typography variant="subtitle2">{auth.email}</Typography>
                </Box>
              </Toolbar>

              <Box p={2} pt={0}>
                <Button
                  color="inherit"
                  size="small"
                  fullWidth
                  onClick={() => {
                    auth.signOut().then(() => {
                      if (typeof callbackLogout == "function") {
                        callbackLogout();
                      }
                    });
                  }}
                  variant="outlined"
                >
                  Logout
                </Button>
              </Box>

              <Divider sx={{ width: "100%" }} />

              <Box sx={{ mt: 1, mb: 1 }}>
                {auth.isAdmin && (
                  <MenuItem color="inherit" component="a" href="/admin">
                    <ListItemIcon sx={{ p: 0, m: 0 }}>
                      <SettingsOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography color="textPrimary" variant="subtitle1">
                          Dashboard Admin
                        </Typography>
                      }
                    />
                  </MenuItem>
                )}

                <MenuItem color="inherit" component="a" href="/user/dashboard">
                  <ListItemIcon sx={{ p: 0, m: 0 }}>
                    <DashboardIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="subtitle1">
                        Dashboard Pameran
                      </Typography>
                    }
                  />
                </MenuItem>

                <MenuItem color="inherit" component="a" href="/spoke">
                  <ListItemIcon sx={{ p: 0, m: 0 }}>
                    <ParkOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="subtitle1">
                        Scene Editor
                      </Typography>
                    }
                  />
                </MenuItem>

                <Divider sx={{ width: "100%" }} />
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AccountPopover;
