import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, Button, Grid, Hidden, IconButton, SvgIcon, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import configs from "../../../../../utils/configs";
import ButtonQuisioner from "./ButtonQuisioner";
import Logo from "./Logo";

const getData = conf => {
  var data = [];

  var ex = conf.APP_CONFIG.external;

  if (ex) {
    var keys = [
      {
        name: "twitter",
        icon: <TwitterIcon />
      },

      {
        name: "facebook",
        icon: <FacebookIcon />
      },

      {
        name: "instagram",
        icon: <InstagramIcon />
      },

      {
        name: "linkedin",
        icon: <LinkedInIcon />
      },

      {
        name: "youtube",
        icon: <YouTubeIcon />
      },

      {
        name: "website",
        icon: <LanguageIcon />
      }
    ];

    keys.forEach(e => {
      if (ex[e.name]) {
        data.push({
          title: e.name,
          url: ex[e.name],
          icon: e.icon
        });
      }
    });
  }

  return data;
};

export function SocialBar({ mobile }) {
  const theme = useTheme();
  const intl = useIntl();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const data = getData(configs);

  const primary = theme.palette.primary;

  const useStyles = makeStyles({
    button: {
      // color: primary.contrastText,
      m: 1,
      "&:hover": {
        // backgroundColor: primary.contrastText,
        // color: primary.main
      }
    }
  });

  const classes = useStyles();

  const doc = intl.formatMessage({
    id: "documentation",
    defaultMessage: "Documentation"
  });

  return (
    <Box
      className="bg-gradient-to-r from-orange-100 to-rose-100"
      sx={{
        width: "100%"
      }}
    >
      <Grid container>
        <Hidden smDown>
          <Grid item md={5} sm={12} container justifyContent="flex-start">
            <div>
              <Button title={doc} className={classes.button} sx={{ m: 1 }} color="inherit" component="a" href="/docs">
                {doc}
              </Button>
              <ButtonQuisioner className={classes.button} sx={{ m: 1 }} color="inherit" />
            </div>
          </Grid>
        </Hidden>

        <Grid item md={2} sm={12} sx={{ p: 1 }} container justifyContent="center">
          <div>
            <Logo
              size={40}
              style={{
                width: "auto",
                maxHeight: "40px"
              }}
            />
          </div>
        </Grid>
        <Grid item md={5} sm={12} container justifyContent={isMobile ? "center" : "flex-end"}>
          <div>
            {data.map((item, idx) => (
              <IconButton
                sx={{ m: 1 }}
                className={classes.button}
                component="a"
                target="_blank"
                key={idx}
                rel="noopener noreferrer"
                href={item.url}
                title={item.title}
              >
                <SvgIcon>{item.icon}</SvgIcon>
              </IconButton>
            ))}
          </div>
        </Grid>

        <Hidden smUp>
          <Grid item md={5} sm={12} container justifyContent="flex-center">
            <div>
              <Button title={doc} className={classes.button} sx={{ m: 1 }} color="inherit" component="a" href="/docs">
                {doc}
              </Button>

              <ButtonQuisioner className={classes.button} sx={{ m: 1 }} color="inherit" />
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
}
SocialBar.propTypes = {
  mobile: PropTypes.bool
};
