import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { str_limit } from "../../ui/user/Helper";

export default function RememberSearch({ value, setValue }) {
  const [arr, setArr] = useState([]);

  useEffect(() => {
    var before = localStorage.getItem("remember");

    if (before) {
      before = JSON.parse(before);

      setArr(before);
    }
  }, []);

  if (arr.length == 0) {
    return null;
  }
  return (
    <Box mb={5}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Search before
          </Typography>
        </Grid>
        <Grid item xs={4} container justifyContent="flex-end">
          <Button
            onClick={() => {
              localStorage.removeItem("remember");
              setArr([]);
            }}
            size="small"
          >
            delete
          </Button>
        </Grid>
      </Grid>

      {arr.map((item, idx) => (
        <Button
          key={idx}
          onClick={() => {
            setValue(item);
          }}
          color="inherit"
          variant={value == item ? "contained" : ""}
          size="small"
          startIcon={<SearchIcon />}
          sx={{ mr: 1, mb: 1 }}
          style={{
            textTransform: "capitalize"
          }}
        >
          {str_limit(item, 20)}
        </Button>
      ))}
    </Box>
  );
}
