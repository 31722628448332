import { Box, Divider, Pagination, Typography } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { SpinnerCenter, useIsMountedRef } from "../../ui/user";
import SearchList from "./SearchList";

function SearchResult({ search, table = "collections", handleClose, searchWords, callback, callbackDone }) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [collections, setCollections] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [times, setTimes] = useState(0);
  const [totalResult, setTotalResult] = useState(0);

  const [errorText, setErrorText] = useState("");
  const [recomendation, setRecomendation] = useState([]);

  const refreshData = useCallback(
    async (search, currentPage) => {
      try {
        if (isMountedRef.current) {
          setIsLoading(true);
        }

        const resp = await axios.get(`/user/api/public/search/${table}/${search}?page=${currentPage}`).catch(err => {
          console.log(err);

          enqueueSnackbar(t("network_error"), {
            variant: "error"
          });

          if (isMountedRef.current) {
            setErrorText(true);
            setIsLoading(false);
          }
        });

        if (isMountedRef.current) {
          console.log(resp.data);
          var result_collections = resp.data.collections;

          let { last_page, current_page, recomendation = null } = result_collections;

          if (recomendation != null) {
            setRecomendation(recomendation);
          }

          setCollections(result_collections.data);

          setLastPage(last_page);
          setCurrentPage(current_page);
          setTotalResult(result_collections.total);

          setTimes(resp.data.time_in_seconds);
          setIsLoading(false);

          setErrorText(false);

          if (typeof callbackDone == "function") {
            callbackDone();
          }
        }
      } catch (error) {
        console.log(error);
        if (isMountedRef.current) {
          setIsLoading(false);
          setErrorText(true);
        }
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    if (search.length > 2) {
      refreshData(search, currentPage);
    }
  }, [search, currentPage]);

  const handleChange = (e, value) => {
    setCurrentPage(value);
  };

  if (errorText) {
    return (
      <Typography sx={{ mt: 2, mb: 2 }} variant="body1" color="textSecondary" align="center" gutterBottom>
        Error
      </Typography>
    );
  }

  if (!isLoading && collections.length == 0 && recomendation.length == 0) {
    return (
      <Typography sx={{ mt: 2, mb: 2 }} variant="body1" color="textSecondary" align="center" gutterBottom>
        Not found
      </Typography>
    );
  }

  return (
    <Box mb={5}>
      <Divider sx={{ mb: 2, mt: 2 }} />

      <Typography variant="h5" gutterBottom>
        Koleksi Pameran
      </Typography>

      {isLoading ? (
        <SpinnerCenter size={30} color="inherit" />
      ) : (
        <>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            {`${totalResult + recomendation.length} result (${times.toFixed(2)} seconds)`}
          </Typography>

          {/* For recomendation */}
          {currentPage == 1 && (
            <>
              {recomendation.length > 0 && (
                <SearchList
                  arr={recomendation}
                  handleClose={handleClose}
                  searchText={search}
                  searchWords={searchWords}
                  callback={callback}
                />
              )}
            </>
          )}

          {/* For collections */}
          <SearchList
            arr={collections}
            handleClose={handleClose}
            searchText={search}
            searchWords={searchWords}
            callback={callback}
          />

          <Box mt={3} display="flex" justifyContent="center">
            <Pagination page={currentPage} onChange={handleChange} color="primary" count={lastPage} size="small" />
          </Box>
        </>
      )}
    </Box>
  );
}

export default SearchResult;
