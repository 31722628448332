import { Card } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { getDynamicTheme, getDynamicThemeNew } from "../Helper";

export default function CardThemedShadow({
  src,
  sx = {},
  disabled = false,
  light = 100,
  noFontColor = false,
  children,
  ...rest
}) {
  const [color, setColor] = useState();
  const isMountedRef = useIsMountedRef();

  const getCardTheme = useCallback(
    async (src, light, noFontColor) => {
      var im = new Image();
      im.src = src;
      im.onload = () => {
        if (isMountedRef.current) {
          setColor(getDynamicThemeNew(im, light, noFontColor));
        }
      };
    },
    [isMountedRef]
  );

  useEffect(() => {
    if (src && color == null) {
      getCardTheme(src, light, noFontColor);
    }
  }, [src]);

  return (
    <Card
      sx={{
        ...(!disabled ? (color ? color : {}) : {}),
        ...sx,
        border: "none"
      }}
      {...rest}
    >
      {children}
    </Card>
  );
}
