import { Avatar, Box, Divider, Typography } from "@mui/material";
import { useMemo } from "react";
import Highlighter from "react-highlight-words";
import { Lister } from "../../../ui/user";
import { ButtonViews } from "../../../ui/user/Button";
import { findChunks } from "../Helper";
import CardResult from "./CardResult";

export default function Events({ search, callbackDone }) {
  const searchWords = useMemo(() => search.split(" "), [search]);

  return (
    <Box sx={{ mb: 5 }}>
      <Lister
        src={`/user/api/public/search/events/${search}`}
        disableEmptyResult
        disableSearch
        callbackDone={callbackDone}
        delayTime={2000}
      >
        {arr => (
          <>
            <Divider sx={{ mb: 2, mt: 2 }} />

            <Typography variant="h5" gutterBottom>
              Events
            </Typography>

            {arr.map((item, idx) => (
              <Box key={idx}>
                <CardResult
                  link={"/user/" + item.username}
                  searchWords={searchWords}
                  image={"/user/storage/events/" + item.image}
                  name={item.event_name}
                  addOnBellowImage={
                    <ButtonViews
                      fullWidth
                      color="inherit"
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      views={item.views}
                    />
                  }
                  addOn={
                    <Box>
                      {item.description && (
                        <Typography variant="body1" gutterBottom>
                          {item.description}
                        </Typography>
                      )}

                      <Box
                        sx={{
                          mt: 2,
                          alignItems: "center",
                          borderRadius: 1,
                          display: "flex",
                          overflow: "hidden"
                        }}
                      >
                        <Avatar
                          src={item.avatar ? "/user/storage/avatar/" + item.avatar : null}
                          sx={{
                            cursor: "pointer",
                            height: 32,
                            width: 32
                          }}
                          alt={item.name}
                          title={item.name}
                        />

                        <Box sx={{ ml: 2 }}>
                          <Typography component="p" variant="body1" gutterBottom>
                            <Highlighter
                              highlightClassName="highlight"
                              searchWords={searchWords}
                              autoEscape={true}
                              findChunks={findChunks}
                              textToHighlight={item.name}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  }
                />
                {/* <CardEventNewest
                  username={item.username}
                  avatar={item.avatar}
                  image={item.image}
                  name={item.name}
                  event_name={item.event_name}
                  views={item.views}
                /> */}
              </Box>
            ))}
          </>
        )}
      </Lister>
    </Box>
  );
}
