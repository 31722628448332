import { Button, Container, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { formSubmit, getLaravelURL } from "../ui/admin/Helper";

export default function Login({ setMode, continueSignIn }) {
  const { enqueueSnackbar } = useSnackbar();

  const doLogin = data => {
    // console.log("Success sign in");
    // console.log(data);
    continueSignIn(data);
  };

  return (
    <>
      <Typography align="center" variant="h3" gutterBottom>
        Sign in
      </Typography>

      <Formik
        enableReinitialize
        initialValues={{
          email: "",
          password: "",
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
          password: Yup.string().max(255).required("Password is required")
        })}
        onSubmit={(values, controls) => {
          formSubmit(values, controls, "post", `${getLaravelURL()}/api/account/login`, enqueueSnackbar, doLogin, {
            isSuccessMessage: false
          });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              label="Email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              type="email"
              value={values.email}
              variant="outlined"
              sx={{ mb: 2 }}
            />

            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              label="Password"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              type="password"
              value={values.password}
              variant="outlined"
            />

            <Container sx={{ mt: 3 }}>
              <Button
                sx={{ mb: 2 }}
                color="primary"
                fullWidth
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                autoFocus
              >
                Sign in
              </Button>

              <Button
                fullWidth
                onClick={() => {
                  setMode("register");
                }}
                component="div"
                color="inherit"
                disabled={isSubmitting}
                variant="contained"
              >
                Register
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </>
  );
}
