import { ThemeProvider } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import useSettings from "../hooks/useSettings";
import { createTheme } from "../ui/theme";
import { Button } from "@mui/material";
import { SnackbarProvider } from "notistack";
import gtm from "../lib/gtm";
import { gtmConfig } from "../lib/config";
import { blue } from "@mui/material/colors";
import { Helmet, HelmetProvider } from "react-helmet-async";

const HubsKitAppProvider = ({ children }) => {
  const notistackRef = useRef();
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  useEffect(() => {
    gtm.initialize(gtmConfig);
    gtm.push({ event: "page_view" });
  }, []);

  return (
    <SnackbarProvider
      dense
      maxSnack={3}
      ref={notistackRef}
      action={key => (
        <Button color="inherit" onClick={onClickDismiss(key)}>
          Dismiss
        </Button>
      )}
    >
      <HelmetProvider>
        <Helmet>
          {/* <link rel="apple-touch-icon" sizes="180x180" href="/images/icons/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/images/icons/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/images/icons/favicon-16x16.png" />
          <link rel="mask-icon" href="/images/icons/safari-pinned-tab.svg" color="#e41c24" /> */}
          <meta name="msapplication-TileColor" content={blue[700]} />
          <meta name="theme-color" content={blue[700]} />
          <meta name="apple-mobile-web-app-status-bar-style" content={blue[700]}></meta>

          {/* <link rel="manifest" href="/manifest.json" /> */}
        </Helmet>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </HelmetProvider>
    </SnackbarProvider>
  );
};

HubsKitAppProvider.propTypes = {
  children: PropTypes.node
};

export default HubsKitAppProvider;
