import { TextField } from "@mui/material";
import { useState, useEffect } from "react";

var timeout = null;
export default function InputText({ name, touched, errors, setValue, value, ...rest }) {
  const [dummy, setDummy] = useState(value);
  useEffect(() => {
    if (dummy != value) {
      setDummy(value);
    }
  }, [value]);

  // error={Boolean(touched[name] && errors[name])}
  // helperText={touched[name] && errors[name]}

  return (
    <TextField
      fullWidth
      name={name}
      {...rest}
      onBlur={() => {
        setValue(dummy);
      }}
      onChange={async e => {
        setDummy(e.target.value);

        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          setValue(e.target.value);
        }, 500);
      }}
      value={dummy}
      variant="outlined"
    />
  );
}
