import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Box, Dialog, DialogContent, Fade, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline" // default center
  }
});

export default function DialogSwitch({
  title,
  open,
  onClose,
  maxWidth = "md",
  breakpoints = "sm",
  useAppBar = false,
  forceFullscreen = null,
  children,
  src = null,
  opacity = 0.4,
  ...rest
}) {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(breakpoints));

  // const color = useDynamicTheme(src, 100, false, 0.3);

  const BootstrapDialogTitle = ({ children, onClose, ...other }) => {
    return (
      <Toolbar disableGutters sx={{ minHeight: "0px!important" }} {...other}>
        <Typography variant="h6">{children}</Typography>
        <Box
          sx={{
            flexGrow: 1
          }}
        />
        {onClose && (
          <IconButton onClick={onClose} color="inherit">
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
    );
  };

  return (
    <Dialog
      fullScreen={forceFullscreen != null ? forceFullscreen : fullScreen}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      TransitionComponent={forceFullscreen == true ? Transition : Fade}
      classes={{ scrollPaper: classes.scrollPaper }}
      disableScrollLock
      // disableEnforceFocus
      hideBackdrop
      PaperProps={{
        sx: {
          backdropFilter: "blur(20px)",
          WebkitBackdropFilter: "blur(20px)",
          backgroundColor: `rgba(255,255,255,${opacity})`
          // ...(color ? { ...color } : { backgroundColor: "rgba(255,255,255,0.3)" })
        }
      }}
    >
      {forceFullscreen == true ? (
        <>
          {useAppBar && (
            <AppBar color="secondary">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    onClose();
                  }}
                  title="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6">{title}</Typography>
              </Toolbar>
            </AppBar>
          )}

          {children}
        </>
      ) : (
        <>
          <DialogContent sx={{ background: "transparent" }} {...rest}>
            <BootstrapDialogTitle onClose={onClose}>{title}</BootstrapDialogTitle>
            <Box pt={1}>{children}</Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
