import { Box, Button, IconButton, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { useWebSettings } from "../Helper";
import DialogSwitch from "../DialogSwitch";

const CSInfo = () => {
  const data = useWebSettings("cs_phone,cs_email,app_name");

  if (data == null) {
    return null;
  }

  return (
    <Box>
      <Typography sx={{ mt: 2 }} variant="body1" gutterBottom>
        Email: {data.cs_email}
      </Typography>

      <Typography variant="body1" gutterBottom>
        Phone: {data.cs_phone}
      </Typography>

      <Button
        color="primary"
        component={Link}
        fullWidth
        sx={{ mt: 2 }}
        target="_blank"
        rel="noopener noreferrer"
        href={`https://api.whatsapp.com/send?phone=${data.cs_phone}&text=Support%20${data.app_name}`}
        variant="contained"
      >
        Chat Whatsapp
      </Button>

      <Button
        color="primary"
        component={Link}
        fullWidth
        sx={{ mt: 2 }}
        rel="noopener noreferrer"
        href={`tel:${data.cs_phone}`}
        variant="contained"
      >
        Telepon
      </Button>

      <Button
        color="primary"
        component={Link}
        fullWidth
        sx={{ mt: 2 }}
        rel="noopener noreferrer"
        href={`mailto:${data.cs_email}`}
        variant="contained"
      >
        Kirim email
      </Button>
    </Box>
  );
};

export default function ButtonHelp({
  color = "secondary",
  variant = "button1",
  label = "Hubungi Customer Service",
  ...rest
}) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {variant == "button1" && (
        <Button
          variant="contained"
          size="medium"
          color={color}
          title="Help"
          startIcon={<ChatBubbleOutlineOutlinedIcon />}
          onClick={() => {
            setOpen(true);
          }}
          {...rest}
        >
          {label}
        </Button>
      )}

      {variant == "button2" && (
        <Button
          variant="outlined"
          size="medium"
          color="inherit"
          title="Help"
          endIcon={<ChatBubbleOutlineOutlinedIcon />}
          onClick={() => {
            setOpen(true);
          }}
          {...rest}
        >
          {label}
        </Button>
      )}

      {variant == "button3" && (
        <Button
          size="medium"
          color="inherit"
          title="Help"
          startIcon={<ChatBubbleOutlineOutlinedIcon />}
          onClick={() => {
            setOpen(true);
          }}
          {...rest}
        >
          {label}
        </Button>
      )}

      {variant == "icon" && (
        <IconButton
          color="inherit"
          title={label}
          onClick={() => {
            setOpen(true);
          }}
          {...rest}
        >
          <ChatBubbleOutlineOutlinedIcon />
        </IconButton>
      )}

      <DialogSwitch
        open={open}
        onClose={handleClose}
        aria-labelledby="Help dialog"
        title="Hubungi customer service"
        forceFullscreen={false}
        maxWidth="xs"
      >
        {open && <CSInfo />}
      </DialogSwitch>
    </>
  );
}
