import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import ColorThief from "./UI/ColorThief";

export function useWebSettings(only = null) {
  const [data, setData] = useState({
    host: "",
    app_name: "",
    title: "",
    description: "",
    cs_phone: "",
    cs_email: ""
  });

  const refreshData = async () => {
    var urlToGet = "/user/api/public/settings" + (only ? "?name=" + only : "");

    var before = sessionStorage.getItem(urlToGet);
    if (before) {
      before = JSON.parse(before);
      setData(before);
    } else {
      const resp = await axios.get(urlToGet);
      // console.log(resp.data);
      setData(resp.data);
      sessionStorage.setItem(urlToGet, JSON.stringify(resp.data));
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  return data;
}

export function useDynamicTheme(src, light = 100, noFontColor = false) {
  const [color, setColor] = useState();
  const isMountedRef = useIsMountedRef();

  const getCardTheme = useCallback(
    async (src, light, noFontColor) => {
      var data = sessionStorage.getItem("color_" + src);
      if (data) {
        try {
          return setColor(JSON.parse(data));
        } catch (error) {}
      } else {
        var im = new Image();
        im.src = src;
        im.onload = () => {
          if (isMountedRef.current) {
            let a = getDynamicTheme(im, light, noFontColor);
            sessionStorage.setItem("color_" + src, JSON.stringify(a));
            setColor(a);
          }
        };
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    if (src) {
      getCardTheme(src, light, noFontColor);
    } else {
      setColor(null);
    }
  }, [src]);

  return color;
}

function adjustBrightnessFromRGB(arrRGB = [0, 0, 0], amount = 10) {
  // to make the colour less bright than the input
  // change the following three "+" symbols to "-"
  var R = arrRGB[0] + amount;
  var G = arrRGB[1] + amount;
  var B = arrRGB[2] + amount;

  if (R > 255) R = 255;
  else if (R < 0) R = 0;

  if (G > 255) G = 255;
  else if (G < 0) G = 0;

  if (B > 255) B = 255;
  else if (B < 0) B = 0;

  return [R, G, B];
}

function getBrightnessFromRGB(arrRGB) {
  var luma = 0.2126 * arrRGB[0] + 0.7152 * arrRGB[1] + 0.0722 * arrRGB[2]; // per ITU-R BT.709
  return luma;
}

export function getDynamicThemeNew(imgElement, light = 100, noFontColor, colorSampling = 6, contrastThreshold = 166) {
  var takeColor = 6;
  var CT = new ColorThief();
  var palettes = CT.getPalette(imgElement, colorSampling);

  var arr = [];
  palettes.forEach(element => {
    // Only pick bright color
    if (getBrightnessFromRGB(element) > light) {
      arr.push(element);
    }
  });

  // Default value or Fallback
  var output = [];
  for (let i = 0; i < takeColor; i++) {
    var theColor = arr[i] ? arr[i] : palettes[i] ? palettes[i] : [255, 255, 255];
    output.push(theColor);
  }

  function formatRGB(arrRGB, alpha = 1) {
    return `rgba(${arrRGB[0]},${arrRGB[1]},${arrRGB[2]},${alpha})`;
  }

  return {
    // boxShadow: `2.8px 2.8px 2.2px ${formatRGB(output[0], 0.02)}, 6.7px 6.7px 5.3px ${formatRGB(
    //   output[1],
    //   0.028
    // )}, 12.5px 12.5px 10px ${formatRGB(output[2], 0.035)}, 22.3px 22.3px 17.9px ${formatRGB(
    //   output[3],
    //   0.042
    // )}, 41.8px 41.8px 33.4px ${formatRGB(output[4], 0.05)}, 100px 100px 80px ${formatRGB(output[5], 0.07)}`

    // boxShadow: `2.8px 2.8px 2.2px ${formatRGB(output[0], 0.5)}, 6.7px 6.7px 5.3px ${formatRGB(
    //   output[1],
    //   0.5
    // )}, 12.5px 12.5px 10px ${formatRGB(output[2], 0.5)}`

    boxShadow: `2.8px 2.8px 2.2px ${formatRGB(output[0], 0.04)}, 6.7px 6.7px 5.3px ${formatRGB(
      output[1],
      0.048
    )}, 12.5px 12.5px 10px ${formatRGB(output[2], 0.045)}, 22.3px 22.3px 17.9px ${formatRGB(
      output[3],
      0.042
    )}, 41.8px 41.8px 33.4px ${formatRGB(output[4], 0.05)}, 100px 100px 80px ${formatRGB(output[5], 0.07)}`
  };
}

export function getDynamicTheme(imgElement, light = 100, noFontColor, colorSampling = 6, contrastThreshold = 166) {
  var takeColor = 4;
  var CT = new ColorThief();
  var palettes = CT.getPalette(imgElement, colorSampling);

  var arr = [];
  palettes.forEach(element => {
    // Only pick bright color
    if (getBrightnessFromRGB(element) > light) {
      arr.push(element);
    }
  });

  // Default value or Fallback
  var output = [];
  var sumBrightness = 0;
  for (let i = 0; i < takeColor; i++) {
    var theColor = arr[i] ? arr[i] : palettes[i] ? palettes[i] : [255, 255, 255];

    sumBrightness += getBrightnessFromRGB(theColor);
    output.push(theColor);
  }

  // the average color is dark or light
  var avgBrightness = output.length == 0 ? 0 : sumBrightness / output.length;
  // Contrast color for the text / font
  var contrastColor = avgBrightness <= contrastThreshold ? "#FFFFFF" : "#000000";

  // If too dark
  if (sumBrightness < 10) {
    let startBrightness = 60;
    for (let i = 0, len = output.length; i < len; i++) {
      output[i] = adjustBrightnessFromRGB(output[i], startBrightness);
      startBrightness += 30;
    }
  }

  // Calculate the difference if the color is similar then make brighter background,
  // so the origin image can be seen
  function deltaE(rgbA, rgbB) {
    let r = Math.abs(rgbA[0] - rgbB[0]);
    let g = Math.abs(rgbA[1] - rgbB[1]);
    let b = Math.abs(rgbA[2] - rgbB[2]);
    return r + g + b;
  }

  var diff = 0;
  for (let i = 0, len = output.length - 1; i < len; i++) {
    diff += deltaE(output[i], output[i + 1]);
  }

  // the different less than threshold
  if (diff < 100) {
    // Change to darken or brighten
    let startBrightness = 40;

    if (contrastColor == "#000000") {
      startBrightness = -40;
    }

    for (let i = 0, len = output.length; i < len; i++) {
      output[i] = adjustBrightnessFromRGB(output[i], startBrightness);

      startBrightness += 10;
    }
  }

  function formatRGB(arrRGB, alpha = 1) {
    return `rgba(${arrRGB[0]},${arrRGB[1]},${arrRGB[2]},${alpha})`;
  }

  return {
    backgroundColor: output[0],
    backgroundImage: `linear-gradient(${getRandomInt(30, 60)}deg, ${formatRGB(output[0])} 0%,${formatRGB(
      output[1]
    )} 46%, ${formatRGB(output[2])} 100%)`,
    ...(noFontColor ? {} : { color: contrastColor })
  };
}

function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

const num_limit = num => {
  let digits = 1;

  let si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

const checkHasLike = id => {
  return localStorage.getItem("liked_" + id) != null;
};

export async function playSound(num = null, callback) {
  // var a = document.getElementById("audio" + num);
  // if (a) {
  //     a.play();
  // }
  var x = document.createElement("AUDIO");
  x.oncanplaythrough = () => {
    x.play();
  };

  if (typeof callback == "function") {
    x.onended = () => {
      callback();
    };
  }

  if (num == null) {
    num = getRandomInt(1, 3);
  }

  x.src = "/user/static/bubble/" + num + ".mp3";
}

function validURL(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

const alphaNumeric = txt => {
  return txt
    .replace(/[^\w\s]/gi, "")
    .trim()
    .replace(/\s+/g, "-")
    .toLowerCase();
};

const titleCase = str => {
  let arr = str.toLowerCase().split(" "),
    out = "",
    i = 0;

  arr.forEach(el => {
    out += el.charAt(0).toUpperCase();
    out += i < 2 ? el.substring(1) + " " : ". ";

    i++;
  });

  return out;
};

function toTitleCase(str) {
  if (typeof str == "string") {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return "";
}

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const str_limit = (str, len = 100) => {
  if (str == null) {
    return "";
  }

  if (str.length > len) {
    str = str.substring(0, len) + "...";
  }
  return str;
};

const getGreetingTime = () => {
  const currentTime = moment();
  if (!currentTime || !currentTime.isValid()) {
    return "Invalid date";
  }

  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = parseFloat(currentTime.format("HH"));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    // Between 12 PM and 5PM
    return <FormattedMessage id="good-afternoon" defaultMessage="Good afternoon" />;
  } else if (currentHour >= splitEvening) {
    // Between 5PM and Midnight
    return <FormattedMessage id="good-evening" defaultMessage="Good evening" />;
  }
  // Between dawn and noon
  return <FormattedMessage id="good-morning" defaultMessage="Good morning" />;
};

function formatDate(date, lang = "id") {
  return moment(date).locale(lang).format("Do MMMM YYYY, HH:mm");
}

export {
  formatDate,
  stripHtml,
  num_limit,
  str_limit,
  checkHasLike,
  validURL,
  getRandomInt,
  titleCase,
  toTitleCase,
  alphaNumeric,
  getGreetingTime
};
