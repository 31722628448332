import React from "react";

import { CircularProgress, Grid, Box, Typography } from "@mui/material";

export default function SpinnerCenter({ label = "" }) {
    return (
        <Box mt={5} mb={5}>
            <Grid container justifyContent="center">
                <CircularProgress color="inherit" />
            </Grid>
            {label && (
                <Box mt={2}>
                    <Typography
                        color="textSecondary"
                        variant="body1"
                        align="center"
                        gutterBottom
                    >
                        {label}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}
