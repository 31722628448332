import React from "react";
import { useLogo } from "../../../../../react-components/styles/theme";
import configs from "../../../../../utils/configs";

const Logo = ({ size = 52, ...rest }) => {
  const src = useLogo();
  // console.log(src);
  return (
    <img
      {...rest}
      title={configs.translation("app-name")}
      alt={configs.translation("app-name")}
      width={size}
      height={size}
      src={src}
    />
  );
};

export default Logo;
