import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, TextField, Toolbar } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { useIsMountedRef } from "../index";

export default function CopyText({ text }) {
  const inputRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState("Copy");

  const isMountedRef = useIsMountedRef();

  const copyToClipboard = useCallback(
    e => {
      let a = inputRef.current;

      a.select();
      a.setSelectionRange(0, 99999);
      document.execCommand("copy");

      if (isMountedRef.current) {
        setCopySuccess("Copied!");
      }

      setTimeout(() => {
        if (isMountedRef.current) {
          setCopySuccess("Copy");
        }
      }, 3000);
    },
    [isMountedRef]
  );

  return (
    <Toolbar disableGutters sx={{ minHeight: "0px!important" }}>
      <TextField
        fullWidth
        size="small"
        inputProps={{
          "aria-label": "copy link",
          ref: inputRef
        }}
        value={text}
        onChange={() => {}}
      />
      <Button
        sx={{ ml: 1 }}
        onClick={copyToClipboard}
        color="inherit"
        size="small"
        disabled={copySuccess == "Copied!"}
        startIcon={<ContentCopyIcon />}
      >
        {copySuccess}
      </Button>
    </Toolbar>
  );
}
