import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button } from "@mui/material";
import { num_limit } from "../Helper";

export default function ButtonViews({ views, ...rest }) {
  return (
    <Button {...rest} size="medium" color="inherit" title="Views" component="div" startIcon={<VisibilityIcon />}>
      {num_limit(views)}
    </Button>
  );
}
