import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { DialogSwitch, Scrollbar } from "../../ui/user";
import { rememberSearch } from "./Helper";
import RememberSearch from "./RememberSearch";
import Events from "./Renderer/Events";
import Users from "./Renderer/Users";
import SearchBox from "./SearchBox";
import SearchResult from "./SearchResult";

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    top: 10
  }
});

export default function SearchSection({ label, customTrigger = null, placeholder, callback = null }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const [done, setDone] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (search.length > 2) {
      setDone(0);
      rememberSearch(search);
    }
  }, [search]);

  return (
    <>
      {customTrigger ? (
        customTrigger(handleOpen)
      ) : (
        <>
          {!open && (
            <SearchBox
              onFocus={handleOpen}
              value={search}
              setValue={setSearch}
              label={label}
              placeholder={placeholder}
            />
          )}
        </>
      )}

      <DialogSwitch
        classes={{
          paper: classes.dialog
        }}
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        title="Explorasi"
        opacity={0.6}
      >
        <Box sx={{ mb: 2 }}>
          <SearchBox value={search} setValue={setSearch} autoFocus={true} label={label} placeholder={placeholder} />
        </Box>
        <Scrollbar style={{ maxHeight: "500px" }}>
          <Box>
            <RememberSearch value={search} setValue={setSearch} />

            {search.length > 2 ? (
              <Box pr={2}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  Hasil pencarian "<span style={{ fontWeight: "bold" }}>{search}</span>"
                </Typography>

                <SearchResult
                  search={search}
                  handleClose={handleClose}
                  searchWords={search.split(" ")}
                  callback={callback}
                  callbackDone={() => {
                    if (done < 1) {
                      setDone(1);
                    }
                  }}
                />

                {done >= 1 && (
                  <Users
                    search={search}
                    callbackDone={() => {
                      if (done < 2) {
                        setDone(2);
                      }
                    }}
                  />
                )}

                {done >= 2 && (
                  <Events
                    search={search}
                    callbackDone={() => {
                      if (done < 3) {
                        setDone(3);
                      }
                    }}
                  />
                )}
              </Box>
            ) : (
              <Typography variant="body1" color="textSecondary" gutterBottom>
                {search.length == 0 ? "Tulis sesuatu" : "Minimal 3 karakter"}
              </Typography>
            )}
          </Box>
        </Scrollbar>
      </DialogSwitch>
    </>
  );
}
