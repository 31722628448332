import axios from "axios";

import { usePaginatedAPI } from "../../react-components/home/usePaginatedAPI";
import { fetchReticulumAuthenticated } from "../../utils/phoenix-utils";
import useAuth from "../../react-components/hooks/useAuth";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { stripHtml, str_limit } from "../ui/user/Helper";

export function showCollectionDetailsInVR(collection) {
  // Show Collection Details when in VR MODE
  var wrapper = document.querySelector("#collection-details");

  if (collection.name) {
    var title = wrapper.querySelector(".collection-title");
    title.setAttribute("text", "value", str_limit(collection.name, 80));
  }

  if (collection.description) {
    var description = wrapper.querySelector(".collection-description");
    description.setAttribute("text", "value", str_limit(stripHtml(collection.description), 480));
  }
  if (collection.creator) {
    var creator = wrapper.querySelector(".collection-creator");
    creator.setAttribute("text", "value", str_limit(collection.creator, 50));
  }
  if (collection.email) {
    var email = wrapper.querySelector(".collection-email");
    email.setAttribute("text", "value", str_limit(collection.email, 50));
  }

  var image = wrapper.querySelector(".collection-image");

  if (collection.file) {
    image.object3D.visible = true;
    image.setAttribute("collection-image-preview", "src", collection.file);
  } else {
    image.object3D.visible = false;
  }

  wrapper.object3D.visible = true;
}

const getRoomDetail = async hub_id => {
  try {
    let parsed = url => {
      let urlParsed = new URL(url);
      return urlParsed.pathname.substring(1);
    };
    var a = parsed(window.location.href).split("/");
    var hub_id = a[0];
    const resp = await axios.get("/user/api/public/rooms/show/" + hub_id);
    if (resp.data.status) {
      return resp.data;
    } else {
      return {
        collections: []
      };
    }
  } catch (error) {
    return {
      collections: []
    };
  }
};

const exactFindCollection = collection_id => {
  // console.log("exactFindCollection");
  var collection = window.COLLECTIONS.find(el => el.collection_id == collection_id);
  if (collection) {
    return collection;
  }
  return null;
};

export async function findCollectionAsync(collection_id = 0) {
  // console.log("FIND COLLECTION");
  var len = window.COLLECTIONS.length;
  var output = null;
  if (len > 0) {
    if (collection_id) {
      // Manual collection Placement
      output = exactFindCollection(collection_id);
    } else {
      // Auto collection Placement
      if (window.COLLECTIONS_IDX <= len - 1) {
        var collection = window.COLLECTIONS[window.COLLECTIONS_IDX];
        window.COLLECTIONS_IDX++;

        if (window.COLLECTIONS_IDX == len) {
          window.COLLECTIONS_IDX = 0;
        }
        output = collection;
      }
    }
  }

  if (output == null && collection_id > 0) {
    // Get collection detail from database
    const resp = await axios.get("/user/api/public/collections/info/" + collection_id);

    // console.log(resp);

    output = resp.data;
  }

  return output;
}

const findCollection = (collection_id = 0) => {
  // console.log("FIND COLLECTION");
  var len = window.COLLECTIONS.length;
  if (len > 0) {
    if (collection_id) {
      // Manual collection Placement
      return exactFindCollection(collection_id);
    } else {
      // Auto collection Placement
      if (window.COLLECTIONS_IDX <= len - 1) {
        var collection = window.COLLECTIONS[window.COLLECTIONS_IDX];
        window.COLLECTIONS_IDX++;

        if (window.COLLECTIONS_IDX == len) {
          window.COLLECTIONS_IDX = 0;
        }
        return collection;
      }
    }
  }

  return null;
};

const findProduct = (collection_id, product_id) => {
  var collection = exactFindCollection(collection_id, false);

  if (collection) {
    var product = collection.products.find(el => el.product_id == product_id);
    return product;
  }

  return null;
};

const sequentialProduct = collection_id => {
  if (typeof window.SEQUENTIAL_PRODUCT === "undefined") {
    window.SEQUENTIAL_PRODUCT = 0;
  }

  var collection = exactFindCollection(collection_id, false);

  if (collection) {
    var len = collection.products.length;
    if (len > 0 && window.SEQUENTIAL_PRODUCT < len) {
      var data = collection.products[window.SEQUENTIAL_PRODUCT];
      window.SEQUENTIAL_PRODUCT++;

      if (window.SEQUENTIAL_PRODUCT == len) {
        window.SEQUENTIAL_PRODUCT = 0;
      }
      return data;
    }
  }

  return null;
};

const getCollectionId = () => {
  var a = new URL(location.href);
  var b = a.pathname.split("/").filter(e => e != "");
  if (b.length > 2) {
    return b[2];
  }
  return null;
};

const makeShareLink = collection_id => {
  var a = new URL(location.href);
  var b = a.pathname.split("/").filter(e => e != "");
  return `${location.origin}/${b[0]}/${b[1]}/${collection_id}`;
};

export function showModalIfExistCollectionId() {
  function getNumberFromUrl(url) {
    const urlParts = url.split("/");
    const lastPart = urlParts[urlParts.length - 1];
    const queryParams = lastPart.split("?")[0];
    return queryParams;
  }

  function isStringNumber(value) {
    return !isNaN(value);
  }
  var a = getNumberFromUrl(location.href);

  if (isStringNumber(a)) {
    // console.log("YERSS");
    window.showDialogInfo(a);
  }
}

export {
  getRoomDetail,
  findCollection,
  exactFindCollection,
  findProduct,
  fetchReticulumAuthenticated,
  useAuth,
  usePaginatedAPI,
  createAndRedirectToNewHub,
  sequentialProduct,
  getCollectionId,
  makeShareLink
};

window.fetchQueue = [];

window.fetchOneByOne = null;

// Fix bug if the collection is a lot
export async function queueGetTexture() {}
