import { Avatar, Box, Divider, Drawer, Hidden, Link } from "@mui/material";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import AccountPopover from "../../../AccountPopover";
import { useAuth } from "../../../helper";
import SignInJWT from "../../../SignInJWT";
import Logo from "./Logo";

const MainSidebar = props => {
  const { onMobileClose, openMobile } = props;
  const auth = useAuth();

  return (
    <Hidden lgUp>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: "background.default",
            width: 256
          }
        }}
      >
        <Box
          sx={{
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 2
          }}
        >
          <Link href="/">
            <Logo />
          </Link>

          {/* <LanguagePopover /> */}

          {/* <Link mt={5} color="textSecondary" href="/docs" underline="none" variant="body1">
            <FormattedMessage id="documentation" defaultMessage="Documentation" />
          </Link> */}

          {[
            {
              translation: "documentation",
              name: "Dokumentasi",
              url: "/docs"
            }
            // {
            //   translation: "features",
            //   name: "Fitur",
            //   url: "/user/features"
            // },
            // {
            //   translation: "pricing",
            //   name: "Harga",
            //   url: "/user/pricing"
            // },
            // {
            //   translation: "blog",
            //   name: "Blog",
            //   url: "/user/blog"
            // }
          ].map((item, idx) => (
            <Link key={idx} mt={5} color="textSecondary" href={item.url} underline="none" variant="body1">
              {/* <FormattedMessage id={item.translation} defaultMessage={item.name} /> */}
              {item.name}
            </Link>
          ))}

          {auth.isSignedIn ? (
            <>
              {[
                {
                  translation: "features",
                  name: "Scene Editor",
                  url: "/spoke"
                },
                {
                  translation: "features",
                  name: "Exhibition Dashboard",
                  url: "/user/dashboard/home"
                }
              ].map((item, idx) => (
                <Link key={idx} mt={5} color="textSecondary" href={item.url} underline="none" variant="body1">
                  {item.name}
                </Link>
              ))}

              <Link mt={5} color="textSecondary" onClick={auth.signOut} underline="none" variant="body1">
                Log out
              </Link>
            </>
          ) : (
            <SignInJWT
              custom_button={handleOpen => (
                <Link mt={5} color="textSecondary" onClick={handleOpen} underline="none" variant="body1">
                  Sign in
                </Link>
              )}
            />
          )}
        </Box>
      </Drawer>
    </Hidden>
  );
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default MainSidebar;
