import axios from "axios";

const axiosInstance = axios.create();

var before = localStorage.getItem("accessToken");
if (before) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${before}`;
}

axiosInstance.interceptors.response.use(
  response => response,
  error => Promise.reject((error.response && error.response.data) || "Something went wrong")
);

export default axiosInstance;
