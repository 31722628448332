import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Button, Divider, Hidden, IconButton, Link, Toolbar } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import PropTypes from "prop-types";
import React from "react";
import useAuth from "../../../../../react-components/hooks/useAuth";
import AccountPopover from "../../../AccountPopover";
import Logo from "./Logo";
import { FormattedMessage } from "react-intl";
import ButtonQuisioner from "./ButtonQuisioner";

const MainNavbar = props => {
  const { onSidebarMobileOpen } = props;
  const auth = useAuth();
  const theme = useTheme();

  const primary = theme.palette.primary;

  const useStyles = makeStyles({
    button: {
      // color: primary.contrastText,
      m: 1,
      "&:hover": {
        // backgroundColor: primary.contrastText,
        // color: primary.main
      }
    }
  });

  const classes = useStyles();

  const navData = [
    // {
    //   translation: "see-exhibition",
    //   name: "Pameran",
    //   url: "/user/exhibitions"
    // },
    {
      translation: "features",
      name: "Fitur",
      url: "/user/features"
    },
    {
      translation: "pricing",
      name: "Harga",
      url: "/user/pricing"
    }
    // {
    //   translation: "blog",
    //   name: "Blog",
    //   url: "/user/blog"
    // }
  ];

  return (
    <AppBar
      // color="inherit"
      elevation={0}
      position="sticky"
      sx={{ backdropFilter: "blur(10px)", backgroundColor: "rgba(255,255,255,0.6)", color: "black" }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden smUp>
          <IconButton aria-label="menu" color="inherit" onClick={onSidebarMobileOpen}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden smDown>
          <Link href="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </Link>
        </Hidden>
        <Box sx={{ flexGrow: 1 }} />

        <ButtonQuisioner color="inherit" sx={{ mr: 2 }} className={classes.button} size="small" />

        <Button
          title="Dokumentasi"
          color="inherit"
          sx={{ mr: 2 }}
          className={classes.button}
          component="a"
          href="/docs"
          size="small"
        >
          <FormattedMessage id="documentation" defaultMessage="Documentation" />
        </Button>

        {auth.isSignedIn && (
          <>
            <Hidden smDown>
              <Button
                title="Scene Editor"
                color="inherit"
                sx={{ mr: 2 }}
                className={classes.button}
                component="a"
                href="/spoke"
                size="small"
              >
                Scene Editor
              </Button>

              <Button
                title="Dashboard Pameran"
                color="inherit"
                sx={{ mr: 2 }}
                className={classes.button}
                component="a"
                href="/user/dashboard/home"
                size="small"
              >
                Exhibition Dashboard
              </Button>
            </Hidden>
          </>
        )}

        <AccountPopover
          sx={{
            ml: 1
          }}
        />
      </Toolbar>
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;
