import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CopyText from "./CopyText";
import ShareListItem from "./ShareListItem";

import LinkIcon from "@mui/icons-material/Link";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

export default function ShareLink({ name, link, customOpen = false, setOpenShare, ...rest }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [getLinkOpened, setGetLinkOpened] = useState(false);

  useEffect(() => {
    if (customOpen) {
      shareNative();
    }
  }, []);

  function shareNative(event) {
    if (navigator.share) {
      navigator
        .share({
          title: name,
          text: `${name} \n`,
          url: link
        })
        .then(() => {
          if (customOpen) {
            setOpenShare(false);
          }
          // console.log("Successful share");
        })
        .catch(error => {
          if (customOpen) {
            setOpenShare(false);
          }
          // console.log("Error sharing", error);
        });
    } else {
      setOpenMenu(!openMenu);
    }
  }

  const arrSosmed = [
    {
      icon: <WhatsAppIcon />,
      url: `https://api.whatsapp.com/send?text=${link}`,
      title: "Whatsapp"
    },
    {
      icon: <TwitterIcon />,
      url: `https://twitter.com/intent/tweet?text=${link}`,
      title: "Twitter"
    },
    {
      icon: <LinkedInIcon />,
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${link}`,
      title: "LinkedIn"
    },
    {
      icon: <LinkIcon />,
      onClick: () => {
        setGetLinkOpened(!getLinkOpened);
      },
      title: "CopyLink"
    }
  ];

  return (
    <Box {...rest}>
      {customOpen == false && (
        <Button
          onClick={shareNative}
          variant={openMenu ? "contained" : "outlined"}
          size="medium"
          color="secondary"
          endIcon={openMenu ? <CloseIcon /> : null}
        >
          Share
        </Button>
      )}

      {openMenu && (
        <>
          <Grid sx={{ mt: 2 }} container spacing={1}>
            {arrSosmed.map((item, idx) => (
              <ShareListItem key={idx} {...item} />
            ))}
          </Grid>

          {getLinkOpened && <CopyText text={link} />}
        </>
      )}
    </Box>
  );
}
