import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useCallback, useEffect, useState } from "react";
import { fetchReticulumAuthenticated, useAuth } from "../../../helper";

export default function SelectScene({ value, setValue, ...rest }) {
  const auth = useAuth();
  const [arr, setArr] = useState([]);
  const theme = useTheme();

  const [tab, setTab] = useState(0);

  console.log(auth);

  const refreshData = useCallback(async () => {
    var uri = "/api/v1/media/search?filter=featured&source=scene_listings";

    if (tab == 1) {
      uri = "/api/v1/media/search?filter=my-scenes&source=scenes&user=" + auth.userId;
    }

    const resp = await fetchReticulumAuthenticated(uri);

    var m = resp.entries;
    if (m) {
      setArr(m);
    }
  }, [tab]);

  useEffect(() => {
    refreshData();
  }, [tab]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box {...rest}>
      <Tabs sx={{ mb: 1 }} centered value={tab} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Features" value={0} />
        <Tab label="My Scene" value={1} />
      </Tabs>

      {arr.length == 0 ? (
        <>
          <Typography variant="h5" sx={{ mt: 5, mb: 5 }} align="center" gutterBottom>
            Empty featured scene !
          </Typography>
        </>
      ) : (
        <Grid container spacing={3}>
          {arr.map((item, idx) => (
            <Grid key={idx} item md={4} sm={6} xs={12}>
              <Card
                sx={{
                  ...(value == item.id
                    ? {
                        border: "4px solid " + theme.palette.primary.main
                      }
                    : {})
                }}
              >
                <CardActionArea
                  onClick={() => {
                    setValue(item.id);
                  }}
                >
                  <CardMedia component="img" src={item.images.preview.url} sx={{ height: "200px" }} />
                  <CardContent>{item.name}</CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}
