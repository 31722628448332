import React from "react";
import ReactDOM from "react-dom";
import { WrappedIntlProvider } from "./react-components/wrapped-intl-provider";
import registerTelemetry from "./telemetry";
import "./utils/theme";

import { AuthContextProvider } from "./react-components/auth/AuthContext";
// import "./react-components/styles/global.scss";
import { ThemeProvider } from "./react-components/styles/theme";
import { store } from "./utils/store-instance";
import HubsKitAppProvider from "./hubs-kit/provider/HubsKitAppProvider";
import { HomePage } from "./hubs-kit/custom/pages/home/HomePage";

registerTelemetry("/home", "Hubs Home Page");

window.APP = { store };

function Root() {
  return (
    <WrappedIntlProvider>
      <ThemeProvider store={store}>
        <AuthContextProvider store={store}>
          <HubsKitAppProvider>
            <HomePage />
          </HubsKitAppProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </WrappedIntlProvider>
  );
}

ReactDOM.render(<Root />, document.getElementById("home-root"));
