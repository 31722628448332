import { Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import { findChunks } from "../Helper";
import Highlighter from "react-highlight-words";

export default function CardResult({ searchWords, image, link = "#", name, addOnBellowImage = null, addOn = null }) {
  return (
    <Card
      sx={{
        mb: 2,
        backgroundColor: "transparent"
      }}
    >
      <CardActionArea component="a" target="_blank" href={link}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} sm={3}>
              <img src={image} style={{ width: "100%", height: "auto", borderRadius: "5px" }} loading="lazy" />
              {addOnBellowImage && addOnBellowImage}
            </Grid>
            <Grid item md={9} sm={3}>
              <Typography variant="h6" gutterBottom>
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={searchWords}
                  autoEscape={true}
                  findChunks={findChunks}
                  textToHighlight={name}
                />
              </Typography>

              {addOn && addOn}
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
