import React from "react";
import { Link, IconButton, Grid, SvgIcon } from "@mui/material";

export default function ShareListItem({ icon, url, title, onClick = null }) {
    return (
        <Grid container justifyContent="center" item xs={3} md={3} lg={3}>
            {onClick != null ? (
                <IconButton onClick={onClick} size="medium" title={title} aria-label={title}>
                    <SvgIcon>{icon}</SvgIcon>
                </IconButton>
            ) : (
                <Link
                    color="textSecondary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={url}
                >
                    <IconButton size="medium" title={title} aria-label={title}>
                        <SvgIcon>{icon}</SvgIcon>
                    </IconButton>
                </Link>
            )}
        </Grid>
    );
}
