import { Avatar, Box, CardActionArea, CardMedia, Grid, Typography } from "@mui/material";
import { ButtonViews } from "../../../../ui/user/Button";
// import CardThemed from "../../../../ui/user/UI/CardThemed";
import CardThemedShadow from "../../../../ui/user/UI/CardThemedShadow";

export default function CardEventNewest({ username, avatar, image, name, event_name, views }) {
  return (
    <CardThemedShadow src={image ? "/user/storage/events/" + image : null} light={150}>
      <CardActionArea
        component={"a"}
        href={"/user/" + username}
        sx={{
          borderRadius: "16px"
        }}
      >
        <CardMedia
          sx={{
            height: "280px",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px"
          }}
          component="img"
          loading="lazy"
          src={"/user/storage/events/" + image}
          alt={name}
          title={name}
        />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item lg={9} md={9} sm={6}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }} gutterBottom>
                {event_name}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={6} container justifyContent="flex-end">
              <ButtonViews color="inherit" disableElevation disableFocusRipple disableRipple views={views} />
            </Grid>
          </Grid>

          <Box
            sx={{
              alignItems: "center",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden"
            }}
          >
            <Avatar
              src={avatar ? "/user/storage/avatar/" + avatar : null}
              sx={{
                cursor: "pointer",
                height: 32,
                width: 32
              }}
              alt={name}
              title={name}
            />

            <Box sx={{ ml: 2 }}>
              <Typography component="p" variant="body1" gutterBottom>
                {name}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
    </CardThemedShadow>
  );
}
