import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

var timeout = null;

export default function SearchBox({
  value,
  setValue,
  onFocus,
  autoFocus = false,
  label = "Search",
  placeholder = "",
  ...rest
}) {
  const doSearch = e => {
    let str = e.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      let text = str
        .replace(/[,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        .toLowerCase()
        .replace(/\s+/g, " ")
        .trim();

      setValue(text);
    }, 1000);
  };

  return (
    <TextField
      {...rest}
      label={label}
      fullWidth
      type="search"
      autoFocus={autoFocus}
      onChange={doSearch}
      variant="standard"
      placeholder={placeholder}
      onFocus={onFocus}
      defaultValue={value}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  );
}
