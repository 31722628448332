import React, { useEffect, useState } from "react";

import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";

import SpinnerCenter from "./SpinnerCenter";
import { useIsMountedRef } from "../user";

function WrapperImageViewer(props) {
    const [open, setOpen] = useState(true);
    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        if (open) {
            setOpen(false);
            setTimeout(() => {
                if (isMountedRef.current) {
                    setOpen(true);
                }
            }, [500]);
        }
    }, [props.src, isMountedRef]);

    if (!open) {
        return null;
    }
    return <ImageViewer {...props} />;
}

class ImageViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this._ismounted = true;
        let el = this.myRef.current;
        let self = this;

        el.onload = function () {
            if (self._ismounted) {
                self.setState({
                    isLoading: false,
                });
            }

            let w = this.width;
            let h = this.height;

            let windowWidth = window.innerWidth;

            if (windowWidth > 400) {
                windowWidth = 400;
            } else {
                windowWidth -= 150;
            }

            let imgZoom = windowWidth / (w > h ? w : h);

            let opt = { show: true, size: "large" };
            const viewer = new Viewer(el, {
                title: false,
                navbar: false,
                inline: true,
                viewed() {
                    viewer.zoomTo(imgZoom);
                },
                hidden: function () {
                    viewer.destroy();
                },
                toolbar: {
                    zoomIn: opt,
                    zoomOut: opt,
                    rotateLeft: opt,
                    rotateRight: opt,
                },

                show: true,
            });
        };
    }
    componentWillUnmount() {
        this._ismounted = false;
    }

    render() {
        let h = window.innerWidth;
        if (h > 400) {
            h = 400;
        }

        if (this.props.height) {
            h = this.props.height;
        }

        return (
            <div className="container" style={{ height: h + "px" }}>
                {this.state.isLoading && (
                    <SpinnerCenter label="loading image ..." />
                )}
                <img
                    ref={this.myRef}
                    style={{
                        display: "none",
                    }}
                    src={this.props.src}
                />
            </div>
        );
    }
}

export default WrapperImageViewer;
