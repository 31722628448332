import { Box, Button, Divider, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { DialogSwitch } from "../../../../ui/user";
import ShareLink from "../../../../ui/user/Share/ShareLink";
import configs from "../../../../../utils/configs";

const quis = defineMessages({
  questionnaire: {
    id: "questionnaire",
    defaultMessage: "Questionnaire"
  },
  advice: {
    id: "advice",
    defaultMessage: "Advice"
  },
  usability: {
    id: "usability",
    defaultMessage: "Usability"
  }
});

export default function ButtonQuisioner(props) {
  const [open, setOpen] = useState(false);
  const [tabValue, setTabs] = useState(0);

  const intl = useIntl();
  const questionnaire = intl.formatMessage(quis.questionnaire);

  const handleChange = (event, newValue) => {
    setTabs(newValue);
  };

  const arr = [
    {
      name: intl.formatMessage(quis.advice),
      url:
        "https://docs.google.com/forms/d/e/1FAIpQLSdPQpGkbRNnVtAlOYP7VGmPQ6LvCcXVbScwdgrsYI6Qk4vA_g/viewform?embedded=true",
      short: "https://forms.gle/rv6Mn6Z79ULCfqUA9"
    },
    {
      name: intl.formatMessage(quis.usability),
      url:
        "https://docs.google.com/forms/d/e/1FAIpQLScMVSi1b14V3eAgb9D_ykEKveA0lM2RVuWpzh-iRtfYFqIrBA/viewform?embedded=true",
      short: "https://forms.gle/PNxEqzWMqzAbhTuK6"
    }
  ];

  return (
    <>
      <Button
        {...props}
        onClick={() => {
          setOpen(true);
        }}
        title={questionnaire}
      >
        {questionnaire}
      </Button>
      <DialogSwitch
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="sm"
        title={questionnaire}
      >
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label={questionnaire}>
              {arr.map((item, idx) => (
                <Tab key={idx} sx={{ p: 0, m: 0 }} label={item.name} value={idx} />
              ))}
            </Tabs>
          </Box>
          <iframe
            src={arr[tabValue].url}
            loading="lazy"
            width="100%"
            height="500"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          >
            Loading…
          </iframe>
          <Divider sx={{ mt: 1, mb: 1, width: "100%" }} />
          <ShareLink link={arr[tabValue].short} name={questionnaire + " " + configs.translation("app-name")} />
        </Box>
      </DialogSwitch>
    </>
  );
}
