import { str_limit } from "../../ui/user/Helper";
import axios from "axios";
import levenshtein from "fast-levenshtein";

const findChunks = ({ autoEscape, caseSensitive, sanitize, searchWords, textToHighlight }) => {
  // console.log(searchWords);
  // console.log(textToHighlight);
  const chunks = [];
  const textLow = textToHighlight.toLowerCase();
  // Match at the beginning of each new word
  // New word start after whitespace or - (hyphen)
  const sep = /[-\s]+/;

  // Match at the beginning of each new word
  // New word start after whitespace or - (hyphen)
  const singleTextWords = textLow.split(sep);

  // It could be possible that there are multiple spaces between words
  // Hence we store the index (position) of each single word with textToHighlight
  let fromIndex = 0;
  const singleTextWordsWithPos = singleTextWords.map(s => {
    const indexInWord = textLow.indexOf(s, fromIndex);
    fromIndex = indexInWord;
    return {
      word: s,
      index: indexInWord
    };
  });

  searchWords = searchWords.filter(v => v.length > 2);

  // Add chunks for every searchWord
  searchWords.forEach(sw => {
    const swLow = sw.toLowerCase();

    let threshold = getThreshold(swLow);

    // Do it for every single text word
    singleTextWordsWithPos.forEach(s => {
      let sim = levenshtein.get(s.word, swLow);
      // console.log({
      //     a: s.word,
      //     b: swLow,
      //     c: sim,
      //     d: s,
      // });

      // console.log([swLow, threshold]);
      if (sim <= threshold || s.word.includes(swLow)) {
        const start = s.index;
        const end = s.index + s.word.length;
        chunks.push({
          start,
          end
        });
      }
    });
  });

  // console.log(chunks);

  return chunks;
};

function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function containOneofthisWord(text, arrWord) {
  arrWord = arrWord.filter(v => v.length > 2);

  for (let i = 0, len = arrWord.length; i < len; i++) {
    var word = arrWord[i];

    var threshold = getThreshold(word);

    if (text.search(word) != -1 || levenshtein.get(text, word) <= threshold) {
      return true;
    }
  }
  return false;
}

function getDescription(text, word) {
  text = text.toLowerCase();
  word = word.toLowerCase();
  // console.log(text.length);
  if (text.length > 200) {
    let arr = text.split(".");
    let arrWord = word.split(" ");
    let newText = "";

    for (let i = 0, len = arr.length; i < len; i++) {
      if (containOneofthisWord(arr[i], arrWord)) {
        // before
        if (arr[i - 1]) {
          newText += arr[i - 1] + ". ";
        }

        // now
        newText += str_limit(arr[i], 200) + ". ";

        // after
        if (arr[i + 1]) {
          newText += arr[i + 1] + ". ";
          i += 2;
        }
      }
      if (newText.length > 400) {
        break;
      }
    }

    if (newText.length < 10) {
      return str_limit(text, 200);
    }
    return newText;
  }

  return text;
}

function getThreshold(textSearch) {
  var threshold = 1;

  var lenSearch = textSearch.length;
  if (lenSearch > 4) {
    threshold = 3;
  } else if (lenSearch == 4) {
    threshold = 2;
  }

  return threshold;
}

function rememberSearch(text) {
  try {
    var before = localStorage.getItem("remember");
    text = text.toLowerCase();

    if (before) {
      before = JSON.parse(before);

      if (!before.includes(text)) {
        before.push(text);
      }

      if (before.length > 5) {
        before.shift();
      }

      localStorage.setItem("remember", JSON.stringify(before));
    } else {
      localStorage.setItem("remember", JSON.stringify([text]));
    }
  } catch (err) {
    console.log(err);
  }
}

async function saveSearchAction(query, term_id) {
  await axios.get(`/user/api/public/actions/${query}/${term_id}`);
}

export { getDescription, stripHtml, rememberSearch, findChunks, saveSearchAction };
