import { Box, Divider, Typography } from "@mui/material";
import { useMemo } from "react";
import { Lister } from "../../../ui/user";
import CardResult from "./CardResult";

export default function Users({ search, callbackDone }) {
  const searchWords = useMemo(() => search.split(" "), [search]);

  return (
    <Box sx={{ mb: 5 }}>
      <Lister
        src={`/user/api/public/search/users/${search}`}
        disableEmptyResult
        disableSearch
        callbackDone={callbackDone}
        delayTime={2000}
      >
        {arr => (
          <>
            <Divider sx={{ mb: 2, mt: 2 }} />

            <Typography variant="h5" gutterBottom>
              Users
            </Typography>

            {arr.map((item, idx) => (
              <Box key={idx}>
                <CardResult searchWords={searchWords} image={"/user/storage/avatar/" + item.avatar} name={item.name} />
              </Box>
            ))}
          </>
        )}
      </Lister>
    </Box>
  );
}
