import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import useAuth from "../../react-components/hooks/useAuth";
import useIsMountedRef from "../hooks/useIsMountedRef";
import { DialogSwitch } from "../ui/user";
import Login from "./Login";
import Register from "./Register";

export default function SignInJWT({ custom_button, callback, ...rest }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [mode, setMode] = useState("signin"); // sign in / register

  const auth = useAuth();

  const [loading, setLoading] = useState(false);
  const isMounted = useIsMountedRef();

  const continueSignIn = useCallback(
    resp => {
      // console.log("Continue Sign in");
      // console.log(resp);
      localStorage.setItem("accessToken", resp.accessToken);

      var user = resp.user;
      setOpen(false);
      setLoading(true);
      localStorage.setItem("user_profile", JSON.stringify(user));
      enqueueSnackbar("Wellcome " + user.name, {
        variant: "info",
        persist: false
      });

      auth.signIn(user.email).then(() => {
        if (isMounted.current) {
          setLoading(false);
          if (typeof callback == "function") {
            callback();
          }
        }
      });
    },
    [isMounted]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    var continueURL = searchParams.get("sign_in");
    if (continueURL) {
      enqueueSnackbar("You must login to continue!", {
        variant: "warning",
        persist: false
      });
      handleOpen();
      callback = () => {
        window.location = continueURL;
      };
    }
  }, []);

  if (window.openDialogLogin) {
    return (
      <>
        {custom_button ? (
          custom_button(window.openDialogLogin)
        ) : (
          <Button onClick={window.openDialogLogin} color="inherit">
            Sign in
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      {custom_button ? (
        custom_button(handleOpen)
      ) : (
        <Button onClick={handleOpen} color="inherit" {...rest}>
          Sign in
        </Button>
      )}

      <DialogSwitch
        maxWidth={"xs"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        className="pev-auto"
        forceFullscreen={false}
      >
        <Box>
          {mode == "signin" ? (
            <Login continueSignIn={continueSignIn} setMode={setMode} />
          ) : (
            <Register continueSignIn={continueSignIn} setMode={setMode} />
          )}
        </Box>
      </DialogSwitch>
    </>
  );
}
