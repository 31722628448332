import axios from "axios";

const validURL = str => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

function getExtension(filename) {
  if (typeof filename == "string") {
    return filename.split(".").pop();
  }
  return "";
}

const isImageFile = url => {
  if (typeof url == "string") {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }
  return false;
};

const isDocument = url => {
  if (typeof url == "string") {
    return url.match(/\.(pdf|docx|doc|pptx)$/) != null;
  }
  return false;
};

const normalize = text => {
  if (text == null) {
    return "";
  }

  let isYoutube = text.match(/youtu/) != null;

  let isGoogleDrive = text.match(/drive\.google\.com/) != null;

  if (isYoutube) {
    let a = text.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/);

    let b = text.match(/youtu\.be.*(.{11})/);
    let youtube_video_id = "";
    if (a != null) {
      youtube_video_id = a.pop();
    } else if (b != null) {
      youtube_video_id = b.pop();
    } else {
      youtube_video_id = text;
    }

    if (youtube_video_id.length == 0) {
      return "";
    }

    return "https://youtube.com/embed/" + youtube_video_id;
  } else if (isGoogleDrive) {
    let isDone = text.match(/preview/) != null;

    let isViewExist = text.match(/view/) != null;

    if (!isDone) {
      if (!isViewExist) {
        if (text[text.length - 1] == "/") {
          return text + "preview";
        }

        return text + "/preview";
      }

      return text.replace("view", "preview");
    }

    if (isDone) {
      return text;
    }
  }

  return "";
};

const alphaNumeric = txt => {
  return txt
    .replace(/[^\w\s]/gi, "")
    .trim()
    .replace(/\s+/g, "-")
    .toLowerCase();
};

const titleCase = str => {
  let arr = str.toLowerCase().split(" "),
    out = "",
    i = 0;

  arr.forEach(el => {
    out += el.charAt(0).toUpperCase();
    out += i < 2 ? el.substring(1) + " " : ". ";

    i++;
  });

  return out;
};

const makeName = s => {
  let n = s.substr(s.lastIndexOf("\\") + 1);
  if (n.length > 50) {
    n = n.substring(0, 50);
  }

  return n;
};

function getLaravelURL() {
  return "/user";
  // const { hostname } = new URL(location.origin);
  // var URL_LARAVEL_API = hostname + ":8000";
  // if (hostname == "localhost") {
  //   URL_LARAVEL_API = `http://localhost:8000`;
  // }
  // return URL_LARAVEL_API;
}

const readErrorMessage = message => {
  try {
    let a = message;
    if (typeof message == "string") {
      a = JSON.parse(message);
    }

    let out = "";
    Object.keys(a).map(function(key, index) {
      out += a[key] + " | ";
    });
    return out;
  } catch (e) {
    return message;
  }
};

const formSubmit = async (
  values,
  { resetForm, setErrors, setStatus, setSubmitting },
  method,
  url,
  enqueueSnackbar = null,
  callback,
  sendConfig
) => {
  let { isSendFile = true, isSuccessMessage = true, isResetForm = true, successMessage = null } = sendConfig;

  function errorAct(message) {
    let formated = readErrorMessage(message);

    setStatus({ success: false });
    setErrors({ submit: formated });

    if (enqueueSnackbar) {
      enqueueSnackbar(formated, {
        variant: "error"
      });
    }
    setSubmitting(false);
  }

  try {
    if (isSendFile) {
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };

      const formData = new FormData();

      if (method == "put") {
        formData.append("_method", "put");
        method = "post";
      }

      Object.keys(values).map(function(key, index) {
        formData.append(key, values[key]);
      });

      var response = await axios[method](url, formData, config);
    } else {
      if (method == "put") {
        values = {
          ...values,
          _method: "put"
        };
        method = "post";
      }
      var response = await axios[method](url, values);
    }

    const { status, message } = response.data;

    // console.log(message);
    setSubmitting(false);

    if (isResetForm) {
      resetForm();
    }
    setStatus({ success: true });

    if (isSuccessMessage) {
      if (enqueueSnackbar) {
        let b = method == "post" ? "created" : "updated";
        let text = successMessage ? successMessage : `Successfully ${b} a new item`;

        enqueueSnackbar(text, {
          variant: "success"
        });
      }
    }

    if (typeof callback == "function") {
      callback(response.data);
    }
  } catch (err) {
    // console.dir(err);
    setSubmitting(false);
    if (typeof err == "string") {
      errorAct(err + " | Please check your internet connection!");
    } else {
      if (err.response) {
        if (err.response.status == 401) {
          errorAct("Wrong email or password !");
        } else if (err.response.status == 400) {
          errorAct(err.response.data);
        } else {
          errorAct(err.message);
        }
      }
    }
  }
};

export {
  validURL,
  normalize,
  makeName,
  isImageFile,
  isDocument,
  getExtension,
  formSubmit,
  getLaravelURL,
  alphaNumeric,
  titleCase
};
