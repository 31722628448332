import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import axios from "../../lib/axios";
import { SpinnerCenter, useIsMountedRef } from "../user";
import Search from "./Search";

export default function Lister({
  children,
  src = "/api/public",
  addUrl = null,
  beforeResult = null,
  defaultValue = [],
  navbar,
  placeholder = "Name",

  delayTime = 0,
  disableSearch = false,
  disableEmptyResult = false,

  customSearch = null,
  customSort = [],
  defaultSort = "",
  defaultOrder = "",

  useTotal = false,

  category = "",

  callbackDone = null,

  ...rest
}) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [arr, setArr] = useState(defaultValue);

  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [total, setTotal] = useState();
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(defaultSort);
  const [order, setOrder] = useState(defaultOrder);

  const sortOptions = [
    {
      value: "name",
      name: "Name"
    },
    {
      value: "created_at",
      name: "Created at"
    },
    {
      value: "updated_at",
      name: "Updated at"
    },
    ...customSort
  ];

  const refreshData = () => {
    getData(src, currentPage, search, addUrl, sort, order, category);
  };

  const getURL = (src, currentPage, search, sort, order, addUrl) => {
    var BaseUrl = `${src}?page=${currentPage}`;

    if (location.host.includes(["localhost", "localhost"])) {
      var BaseUrl = `https://${location.host}:4000` + `${src}?page=${currentPage}`;
    } else {
      var BaseUrl = `https://${location.host}` + `${src}?page=${currentPage}`;
    }

    if (search.length > 2) {
      BaseUrl += `&search=${search}`;
    }

    if (sort.length != "") {
      BaseUrl += `&sort=${sort}`;
    }

    if (order.length != "") {
      BaseUrl += `&order=${order}`;
    }

    if (addUrl) {
      BaseUrl += addUrl;
    }

    return BaseUrl;
  };

  const getData = useCallback(
    async (src, currentPage, search, addUrl, sort, order, category) => {
      if (isMountedRef.current) {
        setIsLoading(true);
      }

      var BaseUrl = getURL(src, currentPage, search, sort, order, addUrl);

      var resp = await axios.get(BaseUrl).catch(() => {
        setIsLoading(false);
        enqueueSnackbar("Network error please reload this page!", {
          variant: "error"
        });
      });

      if (isMountedRef.current) {
        let { data, last_page, current_page } = resp.data;

        if (resp.data.total) {
          setTotal(resp.data.total);
        }

        // console.log(resp.data);
        setArr(data);
        setLastPage(last_page);
        setCurrentPage(current_page);
        setIsLoading(false);

        if (typeof callbackDone == "function") {
          callbackDone();
        }
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    // if (delayTime > 0) {
    //   setTimeout(() => {
    //     refreshData();
    //   }, delayTime);
    // } else {
    refreshData();
    // }
  }, [src, currentPage, search, addUrl, sort, order, category]);

  const handleChange = (e, value) => {
    setCurrentPage(value);
  };

  return (
    <Box {...rest}>
      {!disableSearch && (
        <>
          {customSearch ? (
            customSearch(setSearch, isLoading, setIsLoading)
          ) : (
            <Grid container spacing={2} sx={{ mb: 2 }}>
              {navbar && (
                <Grid item md={3} sm={12} xs={12}>
                  {navbar(refreshData)}
                </Grid>
              )}

              <Grid item md={navbar ? 4 : 6} sm={6} xs={12}>
                <Search setSearch={setSearch} placeholder={placeholder} label={"search"} size="small" />
              </Grid>

              <Grid item md={navbar ? 5 : 6} sm={6} xs={12} container justifyContent="flex-end">
                <Box>
                  {useTotal && <Button sx={{ mr: 2 }}>Total {total} data</Button>}

                  <FormControl variant="outlined" size="small" sx={{ mr: 2, minWidth: 100 }}>
                    <InputLabel id="sortBy">Sort By</InputLabel>
                    <Select
                      labelId="sortBy"
                      value={sort}
                      label="Sort by"
                      onChange={e => {
                        setSort(e.target.value);
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {sortOptions.map((item, idx) => (
                        <MenuItem key={idx} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                    <InputLabel id="orderBy">Order By</InputLabel>
                    <Select
                      value={order}
                      labelId="orderBy"
                      label="Order by"
                      onChange={e => {
                        setOrder(e.target.value);
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value="ASC">Ascending</MenuItem>
                      <MenuItem value="DESC">Descending</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      )}

      {beforeResult && beforeResult(refreshData)}
      {!isLoading ? (
        <>
          {arr.length > 0 ? (
            <>
              {children(arr, refreshData)}

              <Box mt={3} display="flex" justifyContent="center">
                <Stack spacing={3}>
                  <Pagination
                    page={currentPage}
                    onChange={handleChange}
                    color="primary"
                    count={lastPage}
                    size="small"
                  />
                </Stack>
              </Box>
            </>
          ) : (
            <>
              {!disableEmptyResult && (
                <Typography align="center" variant="h5" color="textSecondary" gutterBottom sx={{ mt: 5, mb: 5 }}>
                  Empty data
                </Typography>
              )}
            </>
          )}
        </>
      ) : (
        <SpinnerCenter label="loading ..." />
      )}
    </Box>
  );
}
