import { Box, Button, Container, Grid, Hidden, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import useAuth from "../../../../react-components/hooks/useAuth";
import configs from "../../../../utils/configs";
import { Lister } from "../../../ui/user";
import SearchSection from "../../Search/SearchSection";
import SignInJWT from "../../SignInJWT";
import "../../styles/tw.css";
import CardEventNewest from "./partials/CardEventNewest";
import { CreateRoomButton } from "./partials/CreateRoomButton";
import MainLayout from "./partials/MainLayout";
import { SocialBar } from "./partials/SocialBar";

export function HomePage() {
  const auth = useAuth();

  return (
    <MainLayout>
      <div
        style={{
          position: "relative"
        }}
      >
        <video
          src={"/user/dinus/v6.mp4"}
          loading="lazy"
          muted
          autoPlay
          loop
          style={{
            objectFit: "cover",
            objectPosition: "center",
            width: "100%",
            minHeight: "600px"
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: 0.9
            // backgroundColor: "rgba(0,0,0,.8)"
          }}
          className="bg-gradient-to-br from-indigo-100 via-red-100 to-yellow-100"
        ></div>
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="h1" align="center" gutterBottom>
              {configs.translation("app-name")}
            </Typography>

            <Typography sx={{ mb: 3 }} variant="body1" align="center" gutterBottom>
              {configs.translation("app-description")}
            </Typography>

            <Grid container justifyContent="center">
              <CreateRoomButton />
            </Grid>
          </Container>
        </div>
      </div>

      <Box
        sx={{
          p: 5
        }}
      >
        <ExploreRoom />
      </Box>
    </MainLayout>
  );
}

function ExploreRoom() {
  const auth = useAuth();
  const [value, setValue] = useState("popular");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ minHeight: "50vh" }}>
      <Box sx={{ borderBottom: 1, mb: 2, borderColor: "divider" }}>
        <Hidden smUp>
          <Box sx={{ mb: 4 }}>
            <SearchSection />
          </Box>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Popular" value={"popular"} />
            <Tab label="Newest" value={"newest"} />
            <Tab label="Yours" value={"my"} />
          </Tabs>
        </Hidden>

        <Hidden smDown>
          <Grid container spacing={2}>
            <Grid item md={9} sm={9}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Popular" value={"popular"} />
                <Tab label="Newest" value={"newest"} />
                <Tab label="Yours" value={"my"} />
              </Tabs>
            </Grid>
            <Grid item md={3} sm={3}>
              <SearchSection />
            </Grid>
          </Grid>
        </Hidden>
      </Box>
      {value == "popular" || value == "newest" ? (
        <Lister customSearch={() => <></>} src={`/user/api/public/featured/events/${value}`}>
          {arr => (
            <>
              <Grid container spacing={5}>
                {arr.map((item, idx) => (
                  <Grid item lg={4} md={6} sm={6} xs={12} key={idx}>
                    <CardEventNewest {...item} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Lister>
      ) : (
        <>
          {auth.isSignedIn ? (
            <Lister customSearch={() => <></>} src={`/user/api/public/featured/events/${value}`}>
              {arr => (
                <>
                  <Grid container spacing={5}>
                    {arr.map((item, idx) => (
                      <Grid item lg={4} md={6} sm={6} xs={12} key={idx}>
                        <CardEventNewest {...item} />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Lister>
          ) : (
            <Box sx={{ py: 10 }}>
              <Typography variant="h3" textAlign="center" gutterBottom>
                Buat pameran virtual
              </Typography>
              <Typography variant="body1" textAlign="center" gutterBottom>
                Login atau buat akun untuk membuat pameran virtual mu di metaverse.
              </Typography>
              <Grid container justifyContent="center">
                <SignInJWT variant="contained" size="large" />
              </Grid>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
